import { combineReducers } from '@reduxjs/toolkit'
import filter from './filterSlice'
import common from './commonSlice'
import selected from './selectedSlice'
import smart from './smartSlice'

const reducer = combineReducers({
    filter,
    common,
    selected,
    smart
})

export default reducer
