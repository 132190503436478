import { createSlice } from '@reduxjs/toolkit'

export const tryAuth0Slice = createSlice({
    name: 'auth/tryAuth0',
    initialState: {
        tryAuth0Field: false,
    },
    reducers: {
        setTryAuth0: (state, action) => {
            state.tryAuth0Field = true
        },
        unSetTryAuth0: (state) => {
            state.tryAuth0Field = false
        },
    },
})

export const {
    setTryAuth0,
    unSetTryAuth0,
} = tryAuth0Slice.actions

export default tryAuth0Slice.reducer
