import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {apiSignIn, apiSignInGoogle, apiSignInMicrosoft, apiSignOut, apiSignUp, loginAuth0} from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
// import {googleLogout} from '@react-oauth/google';
import {resetFilter} from "../../store/trialkey/filterSlice";
import {USE_AUTH0} from "constants/app.constant";
import {useAuth0} from "@auth0/auth0-react";
import {onSignInSuccessAuth0, onSignOutSuccessAuth0} from "../../store/auth/auth0Slice";
import {AUTH0_DOMAIN, AUTH0_GOOGLE_NAME, AUTH0_MICROSOFT_NAME, AUTH0_API_KEY} from "../../constants/api.constant";
// import store from "../../store";
import {getUserProfile} from "../../services/TrialKeyService";

function useAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const query = useQuery()
    const { access, refresh, signedIn } = useSelector((state) => state.auth.session??{ access : null, refresh : null, signedIn : null, msalInstance : null});
    const { accessAuth0, signedInAuth0 } = useSelector((state) => state.auth.auth0??{ accessAuth0 : null, signedInAuth0 : null});

    // const { auth_type } = useSelector((state) => state.auth.user)

    const { logout, getAccessTokenSilently, user } = useAuth0();

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { access, refresh } = resp.data
                dispatch(onSignInSuccess({access, refresh} ))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            // console.log(errors.data)
            return {
                status: 'failed',
                // message: errors?.response?.data?.message || errors.toString(),
                message: errors?.data?.detail || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                // const { token } = resp.data
                const { access, refresh } = resp.data
                dispatch(onSignInSuccess(access, refresh))
                // dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                // message: errors?.response?.data?.message || errors.toString(),
                message: errors?.data?.detail || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        if (USE_AUTH0) logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        }).then()
        dispatch(resetFilter())
        dispatch(onSignOutSuccess())
        dispatch(onSignOutSuccessAuth0())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        //await apiSignOut()
        handleSignOut()
    }

    const signInGoogle = async (values) => {
        try {
            const resp = await apiSignInGoogle(values)
            if (resp.data) {
                const { access, refresh } = resp.data
                dispatch(onSignInSuccess({access, refresh} ))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            console.log(errors.data)
            return {
                status: 'failed',
                message: errors?.data?.message || errors.toString(),
            }
        }
    }

    const signInMicrosoft = async (values) => {
        try {
            const resp = await apiSignInMicrosoft(values)
            if (resp.data) {
                const { access, refresh } = resp.data
                dispatch(onSignInSuccess({access, refresh} ))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            console.log(errors.data)
            return {
                status: 'failed',
                message: errors?.data?.message || errors.toString(),
            }
        }
    }

    const signAuth0 = async () => {
        const domain = AUTH0_DOMAIN;
        const auth_types = [AUTH0_GOOGLE_NAME, AUTH0_MICROSOFT_NAME]
        try {
            // const accessAuth0 = await getAccessTokenSilently();
            const accessAuth0 = await getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://${domain}/api/v2/`,
                    // scope: "read:current_user",
                },
            });

            dispatch(onSignInSuccessAuth0({accessAuth0}));
            // const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
            // const metadataResponse = await fetch(userDetailsByIdUrl, {
            //     headers: {
            //         Authorization: `Bearer ${accessAuth0}`,
            //     },
            // });
            // const {user_metadata} = await metadataResponse.json();
            // dispatch(setUser(user_metadata))
            // console.log("here", user)
            if (auth_types.includes(user.sub.split('|')[0])){
                const resp = await loginAuth0({email: user.email, given_name: user.given_name, family_name: user.family_name, sub: user.sub, apiKey: AUTH0_API_KEY});
                if (resp.data) {
                    dispatch(setUser(resp.data));
                }
            } else {
                const resp = await getUserProfile()
                if (resp.data.current_user) {
                    dispatch(setUser(resp.data.current_user));
                }
            }
            // dispatch(onSignInSuccessAuth0({accessAuth0}));
            const redirectUrl = query.get(REDIRECT_URL_KEY)
            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            )
        } catch (e) {
            console.log(e.message);
        }
    };

    const getAuthenticated = () => {
        return USE_AUTH0 ? accessAuth0 && signedInAuth0 : access && refresh && signedIn
    }

    return {
        // authenticated: token && signedIn,
        // authenticated: access && refresh && signedIn,
        authenticated: getAuthenticated(),
        signIn,
        signUp,
        signOut,
        signInGoogle,
        signInMicrosoft,
        signAuth0
    }
}

export default useAuth
