import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import auth0 from './auth0Slice'
import tryAuth0 from './tryAuth0Slice'

const reducer = combineReducers({
    session,
    user,
    auth0,
    tryAuth0
})

export default reducer
