import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentSponsor: {
        value: '',
        label: ''
    },
    currentCondition: {
        value: '',
        label: ''
    },
    searchValue: '',
    searchHistory: [],
    pagesScrollYHistory: [],
}

export const filterSlice = createSlice({
    name: 'trialkey/filter',
    initialState,
    reducers: {
        setCurrentSponsor: (state, action) => {
            state.currentSponsor.value = action.payload.value
            state.currentSponsor.label = action.payload.label
            state.currentCondition.value = ''
            state.currentCondition.label = ''
        },
        setCurrentCondition: (state, action) => {
            state.currentCondition.value = action.payload.value
            state.currentCondition.label = action.payload.label
            state.currentSponsor.value = ''
            state.currentSponsor.label = ''
        },
        resetFilter: () => initialState,
        setCurrentSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
        setSearchHistory: (state, action) => {
            state.searchHistory.push(action.payload);
        },
        resetSearchHistory: (state, action) => {
            state.searchHistory = []
        },
        resetPagesScrollYHistory: (state, action) => {
            state.pagesScrollYHistory = []
        },
        setPagesScrollYHistory: (state, action) => {
            const { pageId, pageScrollY } = action.payload;
            const existingPage = state.pagesScrollYHistory.find(item => item.pageId === pageId);
            if (existingPage) {
                existingPage.pageScrollY = pageScrollY;
            } else {
                state.pagesScrollYHistory.push({ pageId, pageScrollY });
            }
        },
        delPagesScrollYHistory: (state, action) => {
            const pageId = action.payload;
            state.pageScrollY = state.pageScrollY.filter(item => item.pageId !== pageId);
        },
    },
})

export const {
    setCurrentSponsor,
    setCurrentCondition,
    resetFilter,
    setCurrentSearchValue,
    setSearchHistory,
    resetSearchHistory,
    resetPagesScrollYHistory,
    setPagesScrollYHistory,
    delPagesScrollYHistory,
} = filterSlice.actions

export default filterSlice.reducer
