export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const GOOGLE_OAUTH2_KEY = process.env.REACT_APP_GOOGLE_OAUTH2_KEY || ''
export const MICROSOFT_OAUTH2_ID = process.env.REACT_APP_MICROSOFT_OAUTH2_ID || ''
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || ''
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
export const AUTH0_GOOGLE_NAME = process.env.REACT_APP_AUTH0_GOOGLE_NAME || ''
export const AUTH0_MICROSOFT_NAME = process.env.REACT_APP_AUTH0_MICROSOFT_NAME || ''
export const AUTH0_API_KEY = process.env.REACT_APP_AUTH0_API_KEY || ''
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
