const appConfig = {
    apiPrefix: process.env.REACT_APP_API_ENDPOINT || 'http://127.0.0.1:8000/api/v1',
    mongodbData: true,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
