import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    smartSearchTrials: [],
}

export const smartSlice = createSlice({
    name: 'trialkey/smart',
    initialState,
    reducers: {
        setSmartSearchTrials: (state, action) => {
            state.smartSearchTrials = [...action.payload.value];
        },
        resetSmartSearchTrials: (state) => initialState,
    },
});

export const { setSmartSearchTrials, resetSmartSearchTrials } = smartSlice.actions

export default smartSlice.reducer
