import axios from 'axios'
import appConfig from 'configs/app.config'
import {REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE} from 'constants/api.constant'
import store from '../store'
import {onSignOutSuccess, setToken} from '../store/auth/sessionSlice'
import {setUser} from '../store/auth/userSlice'
import {jwtDecode} from "jwt-decode";

const isTokenExpiringSoon = (token, thresholdMinutes = 2) => {
    const decoded = jwtDecode(token);
    const exp = decoded.exp * 1000; // JWT exp is in seconds, convert to milliseconds
    const now = Date.now();
    const threshold = thresholdMinutes * 60 * 1000; // convert threshold to milliseconds
    return (exp - now) < threshold;
}

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

const RefreshService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

let isRefreshing = false;
let refreshSubscribers = [];

const onRrefreshed = (token) => {
    refreshSubscribers.map((callback) => callback(token));
}

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
}

BaseService.interceptors.request.use(
    async (config) => {
        const { auth } = store.getState();
        let accessToken = auth.session.access;
        let refreshToken = auth.session.refresh;

        if (accessToken && isTokenExpiringSoon(accessToken)) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const rs = await RefreshService.post("/auth/refresh/", {
                        refresh: refreshToken,
                    });
                    const { token, user } = rs.data;
                    const { access } = token;
                    store.dispatch(setToken({ access }));
                    store.dispatch(setUser(user));
                    accessToken = access;
                    isRefreshing = false;
                    onRrefreshed(accessToken);
                    refreshSubscribers = [];
                } catch (error) {
                    store.dispatch(onSignOutSuccess());
                    window.location = '/sign-in';
                    return Promise.reject(error);
                }
            } else {
                return new Promise((resolve) => {
                    addRefreshSubscriber((newToken) => {
                        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${newToken}`;
                        resolve(config);
                    });
                });
            }
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
                ] = `${TOKEN_TYPE}${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

BaseService.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        if (err.response) {
            if (err.response.status === 401) {
                store.dispatch(onSignOutSuccess());
                window.location = '/sign-in';
            }
        }
        return Promise.reject(err.response);
    }
);

export default BaseService;
