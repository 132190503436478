import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        // url: '/sign-in',
        url: '/auth/login/',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/auth/register/',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    // return ApiService.fetchData({
    //     // url: '/sign-out',
    //     url: '/auth/logout/',
    //     method: 'post',
    //     data,
    // })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        // url: '/forgot-password',
        url: '/auth/password_reset/',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/auth/password_reset/',
        method: 'post',
        data,
    })
}

export async function apiSignInGoogle(data) {
    return ApiService.fetchData({
        // url: '/sign-in',
        url: '/auth/login_google/',
        method: 'post',
        data,
    })
}

export async function apiSignInMicrosoft(data) {
    return ApiService.fetchData({
        // url: '/sign-in',
        url: '/auth/login_microsoft/',
        method: 'post',
        data,
    })
}

export async function loginAuth0 (data, apiKey) {
    return ApiService.fetchData({
        url: '/auth0/login/',
        data,
        method: 'post',
        // headers: {
        //     "x-api-key": apiKey
        // },
    })
}