import axios from 'axios';
import appConfig from 'configs/app.config';
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant';
import store from '../store';
import { onSignOutSuccessAuth0, setTokenAuth0 } from "../store/auth/auth0Slice";

const BaseServiceAuth0 = () => {

    const unauthorizedCode = [401];

    const BaseService = axios.create({
        timeout: 60000,
        baseURL: appConfig.apiPrefix,
    });

    BaseService.interceptors.request.use(
        async (config) => {
            const { auth } = store.getState();
            const accessToken = auth.auth0.accessAuth0;
            if (!accessToken) {
                console.error("Error getting access token!");
                store.dispatch(onSignOutSuccessAuth0());
                window.location = '/sign-in';
                return Promise.reject("Error getting access token!");
            }
            if (accessToken) {
                config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    BaseService.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (unauthorizedCode.includes(err.response.status)) {
                    store.dispatch(onSignOutSuccessAuth0());
                    window.location = '/sign-in';
                    return Promise.reject(err.response);
            }
            return Promise.reject(err.response);
        }
    );

    return BaseService;
};

export default BaseServiceAuth0;