import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {GOOGLE_OAUTH2_KEY, MICROSOFT_OAUTH2_ID} from "./constants/api.constant";
import {Auth0Provider} from "@auth0/auth0-react";
import TagManager from 'react-gtm-module';
import {AUTH0_CLIENT_ID, AUTH0_DOMAIN} from "./constants/api.constant";
import {GTM_ID} from "./constants/google.constant";
import {USE_AUTH0} from "./constants/app.constant";

if (GTM_ID) {
    const tagManagerArgs = {
        gtmId: GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
}

const msalConfig = {
    auth: {
        clientId: MICROSOFT_OAUTH2_ID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: window.location.origin + "/sign-in",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

const renderApp = () => {
    if (USE_AUTH0) {
        return (
            <Auth0Provider
                domain={AUTH0_DOMAIN}
                clientId={AUTH0_CLIENT_ID}
                disableSignUp={true}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: `https://${AUTH0_DOMAIN}/api/v2/`,
                    // scope: "read:current_user update:current_user_metadata"
                }}
            >
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </Auth0Provider>
        );
    } else {
        return (
            <GoogleOAuthProvider clientId={GOOGLE_OAUTH2_KEY}>
                <MsalProvider instance={msalInstance}>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </MsalProvider>
            </GoogleOAuthProvider>
        );
    }
};

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(renderApp())
// root.render(
//     <Auth0Provider
//         domain={AUTH0_DOMAIN}
//         clientId={AUTH0_CLIENT_ID}
//         disableSignUp={true}
//         authorizationParams={{
//             redirect_uri: window.location.origin,
//             audience: `https://${AUTH0_DOMAIN}/api/v2/`,
//             // scope: "read:current_user update:current_user_metadata"
//         }}
//     >
//         <GoogleOAuthProvider clientId={GOOGLE_OAUTH2_KEY}>
//             <React.StrictMode>
//                 <App />
//             </React.StrictMode>
//         </GoogleOAuthProvider>
//     </Auth0Provider>
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
