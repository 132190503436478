import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const environment = process.env.NODE_ENV

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment })
}

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <Theme>
                        <Layout />
                    </Theme>
                    <ToastContainer
                        enableMultiContainer
                        containerId={'A'}
                        position={toast.POSITION.BOTTOM_LEFT}
                    />
                    <ToastContainer
                        enableMultiContainer
                        containerId={'B'}
                        position={toast.POSITION.TOP_RIGHT}
                    />
                    <ToastContainer
                        enableMultiContainer
                        containerId={'C'}
                        position={toast.POSITION.TOP_CENTER}
                    />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default App
