import BaseService from './BaseService'
import BaseServiceAuth0 from "./BaseServiceAuth0";
import { USE_AUTH0} from "../constants/app.constant";

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            const service = USE_AUTH0 ? BaseServiceAuth0() : BaseService;
            // BaseService(param)
            service(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    reject(errors)
                })
        })
    },
}

export default ApiService
