import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getInitialData} from "../../services/TrialKeyService";

export const initialState = {
    phases: [],
    statuses: [],
    study_types: [],
    funder_types: [],
    allocations: [],
    intervention_models: [],
    primary_purposes: [],
    masking: [],
    observational_models: [],
    time_perspectives: [],
    biospecimen_retentions: [],
    arm_types: [],
    intervention_types: [],
    board_statuses: [],
    countries : []
}

export const fetchInitialData = createAsyncThunk(
    'trialkey/common/fetchInitialData',
    async (_, thunkAPI) => {
        try {
            const resp = await getInitialData()
            if (resp.status === 200) {
                if (resp.data) {
                    return resp.data;
                }
            } else {
                return {
                    'statuses': [],
                    'phases': [],
                    'study_types': [],
                    'funder_types': [],
                    'allocations': [],
                    'intervention_models': [],
                    'primary_purposes': [],
                    'masking': [],
                    'observational_models': [],
                    'time_perspectives': [],
                    'biospecimen_retentions': [],
                    'arm_types': [],
                    'intervention_types': [],
                    'board_statuses': [],
                    'countries': []
                };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const commonSlice = createSlice({
    name: 'trialkey/common',
    initialState,
    reducers: {
        setPhases: (state, action) => {
            state.phases = action.payload;
        },
        setStatuses: (state, action) => {
            state.statuses = action.payload;
        },
        resetCommon: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInitialData.fulfilled, (state, action) => {
                state.phases = action.payload.phases;
                state.statuses = action.payload.statuses;
                state.study_types = action.payload.study_types;
                state.funder_types = action.payload.funder_types;
                state.allocations = action.payload.allocations;
                state.intervention_models = action.payload.intervention_models;
                state.primary_purposes = action.payload.primary_purposes;
                state.masking = action.payload.masking;
                state.observational_models = action.payload.observational_models;
                state.time_perspectives = action.payload.time_perspectives;
                state.biospecimen_retentions = action.payload.biospecimen_retentions;
                state.arm_types = action.payload.arm_types;
                state.intervention_types = action.payload.intervention_types;
                state.board_statuses = action.payload.board_statuses;
                state.countries = action.payload.countries;
            });
    },
});

export const { setPhases, setStatuses, resetCommon } = commonSlice.actions

export default commonSlice.reducer
