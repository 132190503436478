import { createSlice } from '@reduxjs/toolkit'

export const auth0Slice = createSlice({
    name: 'auth/auth0',
    initialState: {
        accessAuth0: '',
        signedInAuth0: false,
    },
    reducers: {
        onSignInSuccessAuth0: (state, action) => {
            state.signedInAuth0 = true
            state.accessAuth0 = action.payload.accessAuth0
        },
        onSignOutSuccessAuth0: (state) => {
            state.signedInAuth0 = false
            state.accessAuth0 = ''
        },
        setTokenAuth0: (state, action) => {
            state.accessAuth0 = action.payload.accessAuth0
        },
    },
})

export const {
    onSignInSuccessAuth0,
    onSignOutSuccessAuth0,
    setTokenAuth0,
} = auth0Slice.actions

export default auth0Slice.reducer
