import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    selectedTrials: [],
}

export const selectedSlice = createSlice({
    name: 'trialkey/selected',
    initialState,
    reducers: {
        setSelected: (state, action) => {
            if (!state.selectedTrials.includes(action.payload.value)) {
                state.selectedTrials.push(action.payload.value);
            }
        },
        unsetSelected: (state, action) => {
            state.selectedTrials = state.selectedTrials.filter(
                (trial) => trial !== action.payload.value
            );
        },
        setAllSelected: (state, action) => {
            state.selectedTrials = [...action.payload.value];
        },
        resetSelected: (state) => initialState,
    },
});

export const { setSelected, unsetSelected, resetSelected, setAllSelected } = selectedSlice.actions

export default selectedSlice.reducer
