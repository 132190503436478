import ApiService from "./ApiService"

// export async function getSearchTrials (page=1, data) {
export async function getSearchTrials (data) {
    return ApiService.fetchData({
        url: '/trials/?' + data,
        // url: '/trials/?page=' + page + '&q=' + data + '&filter=' + filter,
        method: 'get',
    })

}
export async function getTrial (data) {
    return ApiService.fetchData({
        url: '/trials/'+data+'/',
        method: 'get',
    })
}

export async function getSponsor (data, page=1) {
    return ApiService.fetchData({
        url: '/trials/sponsors/' + data + '/?page=' + page,
        method: 'get',
    })
}

export async function getCondition (data, page=1) {
    return ApiService.fetchData({
        url: '/trials/conditions/' + data + '/?page=' + page,
        method: 'get',
    })
}

export async function getPhases () {
    return ApiService.fetchData({
        url: '/trials/phases/',
        method: 'get',
    })
}

export async function getStatuses () {
    return ApiService.fetchData({
        url: '/trials/statuses/',
        method: 'get',
    })
}

export async function getFilterSponsor (data) {
    return ApiService.fetchData({
        url: '/trials/sponsors_filter/?q=' + data,
        method: 'get',
    })

}

export async function getFilterCondition (data) {
    return ApiService.fetchData({
        url: '/trials/conditions_filter/?q=' + data,
        method: 'get',
    })

}

export async function refreshToken (data) {
    return ApiService.fetchData({
        url: '/auth/refresh/',
        method: 'post',
        data
    })

}

export async function getUsersList (q='') {
    return ApiService.fetchData({
        url: '/users/get-users-list/?q='+ q,
        method: 'get',
    })
}

export async function setRestrictedUser (data) {
    return ApiService.fetchData({
        url: '/users/set-user-restricted/',
        method: 'post',
        data
    })
}

export async function setTrialDesignUser (data) {
    return ApiService.fetchData({
        url: '/users/set-user-trial-design/',
        method: 'post',
        data
    })
}

export async function deletePermittedTrial (data) {
    return ApiService.fetchData({
        url: '/users/delete-permitted-trial/',
        method: 'post',
        data
    })
}

export async function addUserPermittedTrial (data) {
    return ApiService.fetchData({
        url: '/users/add-permitted-trial/',
        method: 'post',
        data
    })
}

export async function changeUserType (data) {
    return ApiService.fetchData({
        url: '/users/change-user-type/',
        method: 'post',
        data
    })
}

export async function changeUserTypeSelf (data) {
    return ApiService.fetchData({
        url: '/users/change-user-type-self/',
        method: 'post',
        data
    })
}

export async function getSearchTrialsMongo (data) {
    return ApiService.fetchData({
        url: '/trials/search/',
        // url: '/trials/search/?' + data,
        // url: '/trials/?page=' + page + '&q=' + data + '&filter=' + filter,
        data,
        method: 'post',
    })
}

export async function getSearchTrialsElastic (data) {
    return ApiService.fetchData({
        url: '/trials/elasticsearch/',
        // url: '/trials/search/?' + data,
        // url: '/trials/?page=' + page + '&q=' + data + '&filter=' + filter,
        data,
        method: 'post',
    })
}

export async function getSearchTrialsNew (data) {
    return ApiService.fetchData({
        url: '/search/',
        // url: '/trials/search/?' + data,
        // url: '/trials/?page=' + page + '&q=' + data + '&filter=' + filter,
        data,
        method: 'post',
    })
}

export async function getTrialMongo (data) {
    return ApiService.fetchData({
        url: '/trials/trial_mongo/'+data+'/',
        method: 'get',
    })
}

export async function getPatientViewTrialMongo (data) {
    return ApiService.fetchData({
        url: '/trials/patient_view_trial_mongo/'+data+'/',
        method: 'get',
    })
}

export async function getSponsorMongo (data, page=1, sort = 'status', order = 'asc') {
    return ApiService.fetchData({
        url: '/trials/sponsors/mongo/' + data + '/?page=' + page+ '&sort=' + sort + '&order=' + order,
        method: 'get',
    })
}

export async function getConditionMongo (data, page=1, sort = 'status', order = 'asc') {
    return ApiService.fetchData({
        url: '/trials/conditions/mongo/' + data + '/?page=' + page + '&sort=' + sort + '&order=' + order,
        method: 'get',
    })
}

export async function getfavoriteTrials () {
    return ApiService.fetchData({
        url: '/users/favorite-trials/',
        method: 'get',
    })
}

export async function setfavoriteTrials (trial, title, sponsor) {
    const data = {trial, title, sponsor};
    return ApiService.fetchData({
        url: '/users/favorite-trials/',
        method: 'post',
        data
    })
}

export async function deleteFavoriteTrials (trialId) {
    return ApiService.fetchData({
        url: '/users/favorite-trials/' + trialId + '/',
        method: 'delete',
    })
}

export async function getHomeUserTrialsList (page= 1, sort = 'status', order = 'asc') {
    return ApiService.fetchData({
        url: '/trials/favorites/?page=' + page + '&sort=' + sort + '&order=' + order,
        method: 'get',
    })
}

export async function createTrial (data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/',
        method: 'post',
        data
    })
}

export async function getInitialData () {
    return ApiService.fetchData({
        url: '/trials/initial_data/',
        method: 'get',
    })
}

export async function getTrialDesignList () {
    return ApiService.fetchData({
        url: '/trials/trial-design/',
        method: 'get',
    })
}

export async function getTrialDesignEdit (data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/' + data + '/',
        method: 'get',
    })
}

export async function deleteTrialDesignEdit (data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/' + data + '/',
        method: 'delete',
    })
}

export async function updateTrialDesign (trialID, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/' + trialID + '/',
        method: 'patch',
        data
    })
}

export async function createOutcomeMeasures (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/outcome-measures-create/',
        method: 'post',
        data
    })
}

export async function deleteOutcomeMeasures (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/outcome-measures-delete/',
        method: 'post',
        data
    })
}

export async function updateOutcomeMeasures (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/outcome-measures-update/',
        method: 'post',
        data
    })
}

export async function createArmsGroups (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/arm-group-create/',
        method: 'post',
        data
    })
}

export async function deleteArmsGroups (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/arm-group-delete/',
        method: 'post',
        data
    })
}

export async function createInterventions (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/intervention-create/',
        method: 'post',
        data
    })
}

export async function createUpdateEligibility (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/eligibility-create-update/',
        method: 'post',
        data
    })
}

export async function createUpdateEligibilityCriteria (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/eligibility-criteria-create-update/',
        method: 'post',
        data
    })
}

export async function deleteEligibilityCriteria (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/eligibility-criteria-delete/',
        method: 'post',
        data
    })
}

export async function createUpdateOversight (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/oversight-create-update/',
        method: 'post',
        data
    })
}

export async function createUpdateContact (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/contact-create-update/',
        method: 'post',
        data
    })
}

export async function createUpdateFacility (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/facility-create-update/',
        method: 'post',
        data
    })
}

export async function deleteContact (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/contact-delete/',
        method: 'post',
        data
    })
}

export async function deleteFacility (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/facility-delete/',
        method: 'post',
        data
    })
}

export async function downloadCsvSearch (data) {
    return ApiService.fetchData({
        url: '/trials/download-csv-search/',
        method: 'post',
        data,
        responseType: 'blob',
    })
}

export async function getPdf (data) {
    return ApiService.fetchData({
        url: '/trials/download-pdf-report/',
        method: 'post',
        data,
        responseType: 'blob',
    })
}

export async function getUserProfile () {
    return ApiService.fetchData({
        url: '/users/get-user-dashboard/',
        method: 'get',
    })
}

export async function getTrialScore (trialId) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/get-trial-prediction/',
        method: 'get',
    })
}

export async function getTestScore (trialId, data) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/get-trial-prediction-test/',
        method: 'post',
        data
    })
}

export async function getCountries (trialId) {
    return ApiService.fetchData({
        url: '/trials/trial-design/'+trialId+'/get-countries/',
        method: 'get',
    })
}