import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        access: '',
        refresh: '',
        signedIn: false,
        msalInstance: ''
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.access = action.payload.access
            state.refresh = action.payload.refresh
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.access = ''
            state.refresh = ''
            state.msalInstance = ''
        },
        setToken: (state, action) => {
            state.access = action.payload.access
        },
        setMsalInstance: (state, action) => {
            state.msalInstance = action.payload.msal
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken, setMsalInstance } =
    sessionSlice.actions

export default sessionSlice.reducer
