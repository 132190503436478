import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    // avatar: '',
    // userName: '',
    id: 0,
    first_name: '',
    last_name: '',
    is_active: false,
    is_admin: false,
    updated: '',
    created: '',
    email: '',
    restricted: '',
    trial_design: false,
    auth_type: '',
    credential: '',
    authority: [],
    user_type: 0,
    user_company_id: null,
    user_company_type: null,
    user_company_max_seats: null,
    user_subscription_max_seats: null,
    activ_subscription: {},
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        setUserProfile: (state, action) => {
            state.first_name = action.payload.first_name
            state.last_name = action.payload.last_name
            state.user_type = action.payload.user_type
        },
        setUserCompanyMaxSeats: (state, action) => {
            state.user_company_max_seats = action.payload.user_company_max_seats
        },
    },
})

export const {
    setUserProfile,
    setUser ,
    setUserCompanyMaxSeats} = userSlice.actions

export default userSlice.reducer
